export default [
    {
        title: 'menu_messages',
        icon: 'icon-menu_messages',
        route: {
            name: "topic",
            query: {
                filter: {
                    active: {
                        eq: '1'
                    }
                }
            }
        },
        permissions: ['client', 'client_trustee'],
    },
    {
        title: 'menu_deals',
        icon: 'icon-menu_deal',
        route: {name: "deal"},
        permissions: ['client', 'client_trustee'],
    },
    {
        title: 'menu_tracking',
        icon: 'icon-menu_tracking',
        route: {name: "tracking"},
        permissions: ['client', 'client_trustee'],
    },
    // {
    //     title: 'menu_payments',
    //     icon: 'icon-menu_invoices',
    //     route: {name: "invoice"},
    //     permissions: ['client', 'client_trustee'],
    // },
    {
        title: 'menu_company',
        icon: 'icon-menu_company',
        route: {name: "company"},
        permissions: ['client', 'client_trustee'],
    },

    {
        title: 'menu_storage',
        icon: 'icon-menu_storages',
        route: {name: "storage"},
        permissions: ['client', 'client_trustee'],
    },

    {
        title: 'menu_notification',
        icon: 'icon-notifications',
        route: {name: "notifications"},
        permissions: ['client', 'client_trustee'],
    },

    {
        title: 'profile',
        icon: 'icon-menu_profile',
        route: {name: "profile"},
        permissions: ['client', 'client_trustee'],
    },
    //
    // {
    //     title: 'logout',
    //     icon: 'icon-menu_exit',
    //     route: {name: "logout"},
    //     permissions: ['client', 'client_trustee'],
    // }
]
