<template>
    <div style="margin-bottom: 80px">
    <div
        :style="appBarStyle"
        class="header_menu appBar0 "
        :class="!this.showAppBar ? 'hide-icon' : ''"
        ref="menu_top_div"
    >
        <div class="greed_menu">
                <div class="mx-0 px-0 my-0 py-0" v-if="$route.name !== 'uved'" >
                    <v-img @click="goToMenu(home)" v-if="this.showAppBar" src="/img/logo.svg" max-height="49px" contain class="mt-5 cursor_pointer"></v-img>
                    <v-img @click="goToMenu(home)"  v-else src="/img/logo-mini.svg" max-height="20px" contain class="mt-2 cursor_pointer"></v-img>
                </div>
                <div class="mx-0 px-0 my-0 py-0" v-else >
                    <v-img v-if="this.showAppBar" src="/img/logo.svg" max-height="49px" contain class="mt-5"></v-img>
                    <v-img v-else src="/img/logo-mini.svg" max-height="20px" contain class="mt-2"></v-img>
                </div>
                <div style="position: relative">
                    <div class="btn_prev" v-if="btn_prev" @click="scrollMenu(-100)">
                        <v-icon color="primary">mdi-chevron-left</v-icon>
                    </div>
                    <nav class="menu" ref="menu_top_my">
                           <ul>
                            <li v-for="(item, index) in items" :key="index"
                                 v-show="item.visible"
                                 @mouseover="display_menu(true)"
                                 @mouseout="display_menu(false)"
                                 :class="(item.items ?  (item.active ? 'dropdown active_li' : 'dropdown') : (($route.name === item.route.name || item.active === true) ? 'active' : ''))">
                                <router-link :to="goToMenu2(item.route)"
                                   :class="item.active ? 'active_main' : ''"
                                   :key="item.title">
                                            <span class="menu-icon" :class="item.active ? 'active': ''">
                                                <v-icon size="29">${{item.icon}}</v-icon>
                                            </span>
                                    {{ $t(item.title)  }}
                                    <span class="menu-hr"></span>
                                </router-link>
                                <ul v-if="item.items" class="dropdown-menu">
                                    <li  v-for="(subitem, index2) in item.items" :key="index2" v-show="subitem.visible">
                                        <router-link :to="subitem.route"
                                                     :class="$route.name === subitem.route.name  ? 'active_link': ''"
                                                     :key="subitem.title"
                                        >
                                            {{ $t(subitem.title) }}
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li style="width: 20px"></li>
                        </ul>
                    </nav>
                    <div class="btn_next" v-if="btn_next" @click="scrollMenu(100)">
                        <v-icon color="primary">mdi-chevron-right</v-icon>
                    </div>
                </div>
                <div class="mx-0 px-0 my-0 py-0 pt-5">

                    <div  class="d-flex justify-center logo-close">
                        <v-menu open-on-click left top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on" size="50" class="border_blue">
                                    <img v-if="userAvatar !== '/img/avatar.png'" :src="userAvatar" :alt="userName">
                                    <v-icon v-else  color="primary" size="36">
                                        ${{'settingsIcon'}}
                                    </v-icon>
                                </v-avatar>
                            </template>
                            <v-list subheader two-line class="mt-0 pt-2">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-content class="py-0 px-5 title_subtitle">
                                        <v-list-item-title v-text="userName" ></v-list-item-title>
                                        <v-list-item-subtitle v-text="userEmployeePosition"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar size="50" class="border_blue my-0 py-0">

                                        <img v-if="userAvatar !== '/img/avatar.png'" :src="userAvatar" :alt="userName">
                                        <v-icon v-else color="primary" size="36">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-list>
                            <v-list class="py-0" >
                                <v-list-item v-if="can(['administrator'])" @click="changeLanguage('kk', true)" style="min-height: 30px">
                                    <v-list-item-content class="title_subtitle py-1">
                                        <v-list-item-title :class="(lang === 'kk') ? 'active' : ''">{{ $t('kazakh') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="can(['administrator'])" @click="changeLanguage('ru', true)"  style="min-height: 30px">
                                    <v-list-item-content class="title_subtitle py-1">
                                        <v-list-item-title :class="(lang === 'ru') ? 'active' : ''">{{ $t('russian') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="can(['administrator'])" @click="changeLanguage('en', true)"  style="min-height: 30px">
                                    <v-list-item-content class="title_subtitle py-1">
                                        <v-list-item-title :class="(lang === 'en') ? 'active' : ''">{{ $t('english') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="can(['administrator'])" @click="changeLanguage('zh', true)"  style="min-height: 30px" class="">
                                    <v-list-item-content class="title_subtitle py-1">
                                        <v-list-item-title :class="(lang === 'zh') ? 'active' : ''">{{ $t('chinese') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                               <v-list-item v-for="(item, i) in menuClientItems" :key="i" v-show="item.visible"
                                             @click.native="item.action ? item.action() : false" href="javascript:void(0)"
                                             :to="item.to" ripple :exact="item.exact !== undefined ? item.exact : true"
                                            style="min-height: 30px">
                                   <v-list-item-content class="title_subtitle py-1">
                                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>


                            </v-list>
                        </v-menu>
                    </div>
                </div>
            </div>


        <hr>

    </div>
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex"

export default {
    name: "AppTopMenu",
    inject: ['forceRerender'],
    props: {
        showAppBar: Boolean,
    },
    data() {
        return {
            items: [],
            menuClientItems: [],
            scrollAmount: 0,
            btn_prev: false,
            btn_next: false,
            home: {name: "uved"},
        };
    },
    computed: {
        ...mapGetters(['countUnreadNotifications','lang', 'language', 'showNotifyMessages', 'userEmployeePosition', 'userName', 'userAvatar']),

        appBarStyle() {
            return {
                'height': 'auto',
                'width': '100%',
                'min-height': this.showAppBar ? "90px" : "40px",
            };
        },
        routePath() {
            return this.$route.path
        },
    },
    async mounted() {
        await this.navigation()
        await this.menuClientNavigation()
        await this.getBtnNextShow()
    },
    watch: {
        routePath(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.navigation()
            }
        },
    },
    methods:{
        ...mapActions(['setSettings', 'setLanguage', 'setUserName', 'setEmployeePosition', 'setUserAvatar']),

        menuClientNavigation() {
            this.menuClientItems = [
                {
                    title: 'profile',
                    icon: "mdi-account",
                    to: {
                        name: 'profile'
                    },
                    visible: this.$auth.check(),
                    action: this.forceRerender,
                    exact: false
                },
                {
                    title: 'logout',
                    icon: "mdi-logout-variant",
                    visible: this.$auth.check(),
                    action: this.logout
                }
            ]
        },
        navigation() {

            this.items = [

            ]
        },
        closeSubMenu(name_div){
            const element = this.$refs[name_div];
            if (element) {
                element.forEach(el => {
                    el.style.display = 'none';
                    let parent = el.closest('li');
                    if (parent) {
                        let parent_a = parent.querySelector('.menu-hr');
                        if (parent_a) {
                            parent_a.style.display = 'none';
                        }

                    }
                });
            }
        },
        openSubMenu(name_div){
            const element = this.$refs[name_div];
            if (element) {
                element.forEach(el => {
                    el.style.backgroundColor = 'yellow'; // добавление стиля (желтый фон)
                    el.style.display = 'block';
                    let parent = el.closest('li');
                    if (parent) {
                        let parent_a = parent.querySelector('.menu-hr');
                        if (parent_a) {
                            parent_a.style.display = 'block';
                        }

                    }
                });
            }
        },
        goToMenu(route) {
            this.$router.push(route)
        },
        goToMenu2(route) {
            let to = {
                name: route.name
            }

            if(route.query){
                to.query = route.query
            }

            return to
        },
        getBtnNextShow(){
            const menu = this.$refs.menu_top_my;
            const menuWidth = menu.scrollWidth - menu.clientWidth;
            if(menuWidth > 0){
                this.btn_next = true;
            }
            else{
                this.btn_next = false;
            }
        },
        scrollMenu(amount) {

            const menu = this.$refs.menu_top_my;
            //menu.style.display = 'block';
            const menuWidth = menu.scrollWidth - menu.clientWidth;
            if ((amount < 0 && this.scrollAmount > 0) || (amount > 0 && this.scrollAmount < menuWidth)) {
                this.scrollAmount += amount;
                menu.scrollTo({ left: this.scrollAmount, behavior: 'smooth' });
            }

            if(this.scrollAmount > 0){
                this.btn_prev = true;
            }
            else{
                this.btn_prev = false;
            }

            if(this.scrollAmount < menuWidth){
                this.btn_next = true;
            }
            else{
                this.btn_next = false;
            }
            // setTimeout(function() {
            //     this.$refs.menu_top_my.style.display = 'ruby';
            // }, 1000);

        },
        display_menu(status){
            if(status){
                this.$refs.menu_top_div.classList.add('active_menu') ;
            }
            else{
                this.$refs.menu_top_div.classList.remove('active_menu') ;
            }

        },
        async setLang2(lang, reload) {

            if (this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language
                this.setLanguage(this.$auth.user().language)
            }
            if (lang !== undefined) {
                document.documentElement.lang = lang
                this.$i18n.locale = lang
                this.$vuetify.lang.current = lang
            }
            if (typeof this.$i18n.locale !== undefined) {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== undefined) {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            if (reload) {
                this.forceRerender()
            }
        },

        async logout() {
            await this.$auth
                .logout({
                    makeRequest: true,
                    redirect: {
                        name: "login"
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_have_successfully_logged_out'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_occurred_while_exiting'))
                })
        },

        getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        },
        async setLang() {
            let language = this.getCookie('language')
            if (language == null && this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language

            }
            else {
                document.documentElement.lang = language
            }
            this.$vuetify.lang.current = document.documentElement.lang
            this.$i18n.locale = document.documentElement.lang
            this.setLanguage(document.documentElement.lang)
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            this.forceRerender()
        },
        async changeLanguage(lng, reload){

            if(this.$auth && this.$auth.user() && this.$auth.user().id) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (lng) {
                    formData.append('language', lng)
                }
                // Update
                await this.$http
                    .put(`admin/language/${this.$i18n.locale }`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.locale = lng
                        document.documentElement.lang = lng
                        this.$vuetify.lang.current = lng
                        this.$i18n.locale = lng
                        this.setLanguage(lng)
                        this.forceRerender()
                        this.setCookie('language',lng,365)
                    })
                    .catch(err => {
                        // console.log(err.data);
                        this.$toastr.error(this.$t('language_has_not_been_updated'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            }
            else {
                this.locale = lng
                document.documentElement.lang = lng
                this.$vuetify.lang.current = lng
                this.$i18n.locale = lng
                this.setLanguage(lng)
                this.forceRerender()
                this.setCookie('language',lng,365)
            }



        },
        setCookie(name,value,days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        }
    },


};
</script>

<style lang="scss">
.header_menu{
    background-image: linear-gradient(to bottom, rgb(245, 245, 245) 90px, transparent 90px);
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: 5;
}

.appBar0, .appBar0 .greed_menu, .appBar0 .menu{
    height: 100px !important;
}
.active_menu, .active_menu .greed_menu, .active_menu .menu{
    height: 100% !important;
}

.hide-icon{
    background-image: linear-gradient(to bottom, rgb(245, 245, 245) 40px, transparent 90px );
}



.appBar0 .v-toolbar__content{
    margin: 0 !important;
    padding: 0 !important;
}

.greed_menu{
    width: 100%;
    display: grid;
    grid-template-columns: 20% 60% 20%;
}

nav.menu{
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}




nav ul {
    padding: 0 !important;
    white-space: nowrap;
}

nav li {
    display: inline-block;
    margin-right: 20px;
    position: relative;
}

nav a {
    text-decoration: none;
    color: #B3B3B3 !important;
    padding: 14px 20px;
    display: inline-block;
    position: relative;
    font-size: 12px;
    font-weight: 400;
}

.btn_next, .btn_prev{
    height: 90px;
    position: absolute;
    top: 0;
    right: -40px;
    z-index:5;
    background-color: rgba(245, 245, 245, 0.3);
    width: 40px;

}
.btn_prev{
    left: -40px;
}
.btn_next i, .btn_prev i{
    margin-top: 20px;
    font-size: 50px !important;
    transition-duration: 0s, 0s !important;
    transition-delay: 0s, 0s !important;
}

.hide-icon .btn_next i, .hide-icon  .btn_prev i{
    margin-top: 0;
    font-size: 40px !important;
    transition-duration: 0s, 0s;
    transition-delay: 0s, 0s;
}

.hide-icon span.menu-hr, .hide-icon span.menu-icon, .hide-icon .logo-close{
    display: none !important;

}
.hide-icon hr{
    bottom: 2px;
}

.hide-icon .dropdown-menu{
    top: 40px;
}

/* Стили для горизонтальной полоски */
.hide-icon hr{
    top: 40px;
}

.menu-hr{
    position: absolute;
    left: 50%; /* Положение по горизонтали на 50% ширины внешнего div */
    bottom: -22px;
    transform: translate(-50%, -50%); /* Центрирование по центру */
    z-index: 1;
    display: none;
}


/* Стили для знака "+" */
.menu-hr::before {
    z-index: 10;
    content: "+";
    font-size: 1.2em;
    margin-right: 5px;
    background: #007EC9; /* Серый фон */
    border-radius: 50%; /* Создаем круг */
    width: 20px; /* Задаем ширину и высоту для круга */
    height: 20px;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    color: #fff; /* Цвет знака "+" */
}


/* Стили для активного элемента меню */
nav li.active_main a,  nav li.active_main span svg, nav span.active_main svg, nav li a.active_main, nav li a.active_link{
    color: #007EC9 !important;
}

nav li.active_main a::before {
    content: "";
    position: absolute;
    bottom: 0; /* Расположение треугольника выше текста */
    border-style: solid;
    left: 43%;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #007EC9 transparent;
}

a.active_main::before {
    content: "";
    position: absolute;
    bottom: 0; /* Расположение треугольника выше текста */
    border-style: solid;
    left: 43%;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #007EC9 transparent;
}

.hide-icon a.active_main::before{
    bottom: 6px;
}




.menu-icon {
    font-size: 29px;
    display: block;
    text-align: center;
}



.dropdown {
    position: relative;

}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    border: 1px solid #ccc;
    border-top: 0 ;
    top: 90px;
    left: 0;
    white-space: nowrap;
    text-align: left;
    width: 200px;
    background-color: #f7f7f7;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

}


.dropdown-menu li a{
    padding: 0;
    color: #191919 !important;
}
.dropdown-menu li {
    display: block;
    padding: 5px 20px;
    cursor: pointer;
    width: 100%;

}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}

.dropdown-menu li a:hover {
   color: #007EC9 !important;
}



ul li:hover  span.menu-hr {
    display: block;
}

ul li a:hover, ul li a:hover  .menu-icon svg{
    color: #007EC9 !important;
}

</style>
